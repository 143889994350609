import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
} from "@mui/material";
import img from "../../imgAVIF/Rectangle-4.avif";

export default function WhatsappWarn() {
  const [name, setName] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [otpSent, setOtpSent] = useState(false); // Flag for OTP sent status
  const [otpExpired, setOtpExpired] = useState(false); // Flag for expired OTP
  const [otp, setOtp] = useState(""); // To store OTP input
  const [timer, setTimer] = useState(180); // 3 minutes in seconds
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(""); // To store error messages
  const [successMessage, setSuccessMessage] = useState(""); // To store success messages

  // Start timer when OTP is sent
  useEffect(() => {
    let interval = null;

    if (otpSent) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            setOtpExpired(true); // Mark OTP as expired
            setOtpSent(false); // Reset OTP status
            setError(""); // Clear any previous errors
            setSuccessMessage(""); // Clear success message
            setTimer(180); // Reset timer
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [otpSent]);

  // Format timer display
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Handle name and phone number submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");
    const Data = {
      name: name,
      phone_number: whatsappNumber,
    };

    try {
      const response = await fetch(
        "https://biru-langit.com/api/ews/register-phone-number",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(Data),
        }
      );

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || "Failed to register the number.");
      }

      setSuccessMessage("Number successfully registered. Please proceed to receive the OTP.");

      handleResendOtp(); // Send OTP after registration
    } catch (error) {
      setError(error.message);
    }
  };

  // Handle OTP submission
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    try {
      const response = await fetch(
        "https://biru-langit.com/api/ews/verification-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone_number: whatsappNumber,
            otp: otp,
          }),
        }
      );

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || "Failed to verify the OTP.");
      }

      setOpen(true);
      setOtp("");
      setOtpSent(false);
      setOtpExpired(false);
      setName("");
      setWhatsappNumber("");
    } catch (error) {
      setError(error.message);
    }
  };

  // Handle resend OTP
  const handleResendOtp = async () => {
    setError("");
    setSuccessMessage("");
    setOtpExpired(false);

    try {
      const otpResponse = await fetch(
        "https://biru-langit.com/api/ews/send-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ phone_number: whatsappNumber }),
        }
      );

      const otpResult = await otpResponse.json();

      if (!otpResponse.ok) {
        throw new Error(otpResult.message || "Failed to send OTP.");
      }

      setSuccessMessage("OTP has been sent to your WhatsApp!");
      setOtpSent(true);
      setTimer(180); // Reset timer
    } catch (error) {
      setError(error.message);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="bg" style={{ background: "none" }}>
      <Container sx={{ pt: 18, pb: 10 }} maxWidth="sm">
        <div className="box">
          <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
            <Grid
              item
              md={12}
              className="early-warn"
              style={{
                backgroundImage: `url(${img})`,
              }}
            />
            <Grid item md={12} sm={12} xs={12} sx={{ p: 3 }}>
              <Typography component="h1" variant="h4">
                Get an Early Warning?
              </Typography>
              <Typography component="h5">
                Let's go and get an early warning!
              </Typography>

              {error && (
                <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                  {error}
                </Alert>
              )}
              {successMessage && (
                <Alert severity="success" sx={{ mt: 2, mb: 2 }}>
                  {successMessage}
                </Alert>
              )}

              {!otpSent && !otpExpired ? (
                <form onSubmit={handleSubmit}>
                  <TextField
                    margin="dense"
                    required
                    fullWidth
                    label="Name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                  />
                  <TextField
                    margin="dense"
                    required
                    fullWidth
                    label="WhatsApp Number"
                    name="whatsapp"
                    autoComplete="tel"
                    value={whatsappNumber}
                    onChange={(e) => setWhatsappNumber(e.target.value)}
                  />
                  <p>*Make sure your number is correct!</p>
                  <Button
                    type="submit"
                    disabled={!name || !whatsappNumber}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 2 }}
                  >
                    Submit
                  </Button>
                </form>
              ) : (
                <form onSubmit={handleOtpSubmit}>
                  {otpExpired ? (
                    <>
                      <Button
                        onClick={handleResendOtp}
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2, mb: 2 }}
                      >
                        Resend OTP
                      </Button>
                    </>
                  ) : (
                    <>
                      <TextField
                        margin="dense"
                        required
                        fullWidth
                        label="OTP"
                        name="otp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        autoFocus
                      />
                      <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                        Time left: {formatTime(timer)}
                      </Typography>
                      <Button
                        type="submit"
                        disabled={!otp}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 1, mb: 2 }}
                      >
                        Verify OTP
                      </Button>
                    </>
                  )}
                </form>
              )}
            </Grid>
          </Grid>
        </div>
      </Container>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Thank you for subscribing!</DialogTitle>
        <DialogContent>
          Please check your WhatsApp for confirmation. If you do not receive a
          message, check your app settings.
        </DialogContent>
      </Dialog>
    </div>
  );
}
