import React, { useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  Alert,
} from "@mui/material";
import axios from "axios";
import thumbnail from "../../imgAVIF/Rectangle-4.avif"; // Tambahkan import untuk gambar thumbnail

export default function RegisterFlagging() {
  const [fullname, setFullname] = useState(""); // State untuk nama lengkap
  const [email, setEmail] = useState(""); // State untuk email
  const [error, setError] = useState(""); // State untuk pesan error
  const [success, setSuccess] = useState(""); // State untuk pesan sukses
  const [serverErrors, setServerErrors] = useState({}); // State untuk pesan error spesifik dari server

  // Fungsi validasi email menggunakan regex
  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setSuccess("");
    setServerErrors({});

    // Validasi frontend
    if (!fullname.trim()) {
      setError("Full name is required.");
      return;
    }
    if (!isValidEmail(email.trim())) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      const response = await axios.post(
        "https://biru-langit.com/api/Regis/Flagging",
        {
          full_name: fullname.trim(), // Menggunakan full_name sesuai struktur database
          email: email.trim(),
        }
      );
      console.log("Server response:", response.data); // Log respons dari server

      if (response.data.message === "Registrasi berhasil.") {
        setSuccess("Registration successful! You will receive notifications.");
        setFullname(""); // Reset field nama
        setEmail(""); // Reset field email
      } else {
        setError(response.data.message || "Registration failed.");
      }
    } catch (err) {
      console.error("Error response:", err.response); // Log detail error
      console.error("Error data:", err.response?.data); // Log data error untuk debugging
      if (err.response && err.response.status === 422) {
        const { errors } = err.response.data;
        setServerErrors(errors); // Menyimpan pesan error dari server
      } else {
        setError(err.response?.data?.message || "Registration failed.");
      }
    }
  };

  return (
    <div className="bg">
      <Container sx={{ pt: 20, pb: 10 }} maxWidth="md">
        <div className="box-auth">
          <Grid container justifyContent="center">
            <Grid
              className="plane-img"
              item
              md={6}
              sm={12}
              xs={12}
              sx={{
                backgroundImage: `url(${thumbnail})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <Grid item md={6} sm={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 5,
                }}
              >
                <Typography component="h1" variant="h4">
                  Register for Flagging
                </Typography>

                {/* Tampilkan pesan sukses jika registrasi berhasil */}
                {success && (
                  <Alert severity="success" sx={{ width: "100%", mt: 2, mb: 2 }}>
                    {success}
                  </Alert>
                )}

                <Box component="form" sx={{ mt: 3 }} onSubmit={handleSubmit}>
                  <TextField
                    margin="dense"
                    required
                    fullWidth
                    id="fullname"
                    label="Full Name"
                    value={fullname}
                    onChange={(e) => setFullname(e.target.value)}
                    error={!!serverErrors.full_name}
                    helperText={serverErrors.full_name?.[0] || ""}
                  />
                  <TextField
                    margin="dense"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!serverErrors.email}
                    helperText={serverErrors.email?.[0] || ""}
                  />

                  {/* Tampilkan pesan error jika ada */}
                  {error && <Alert severity="error">{error}</Alert>}

                  <Button
                    type="submit"
                    disabled={!fullname || !email}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Register
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}


