import React, { useState } from "react";
import { Button, Container, Typography, Grid } from "@mui/material";
import img from "../../imgAVIF/Rectangle-4.avif";
import EarlyWarn from "./EarlyWarn";
import WhatsappWarn from "./WhatsappWarn"; // Import the new WhatsApp form

export default function EarlyWarningChoice() {
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [showWhatsappForm, setShowWhatsappForm] = useState(false); // New state for WhatsApp

  const handleEmailClick = () => {
    setShowEmailForm(true);
  };

  const handleWhatsappClick = () => {
    setShowWhatsappForm(true); // Display WhatsApp form
  };

  return (
    <div
      className="bg"
      style={{
        backgroundColor: "#e0f7fa",
        padding: "20px",
      }}
    >
      <Container sx={{ pt: 10, pb: 10 }} maxWidth="sm">
        {!showEmailForm && !showWhatsappForm ? ( // Show choice if neither form is active
          <div
            style={{
              boxShadow: "8px 10px 18px rgba(5, 4, 38, 0.503)",
              borderRadius: "20px",
              backgroundColor: "#f9fbff",
              zIndex: 999,
              width: "100%",
              maxWidth: "618px", // Added maxWidth for larger screens
              height: "auto",
              padding: "0",
              position: "relative",
            }}
          >
            <Grid container justifyContent="center" sx={{ textAlign: "center", height: "100%" }}>
              <Grid
                item
                xs={12}
                style={{
                  backgroundImage: `url(${img})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "130px",
                  width: "100%",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                }}
              />
              <Grid item xs={12} sx={{ p: 2, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "calc(100% - 130px)" }}>
                <Typography component="h1" variant="h4" sx={{ mb: 2 }}>
                  Get an Early Warning?
                </Typography>
                <Typography component="h5" sx={{ mb: 2 }}>
                  Let's go and get an early warning!
                </Typography>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#163461",
                        width: "100%", // Full width on smaller screens
                        height: "47px",
                      }}
                      onClick={handleEmailClick}
                    >
                      Email
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#163461",
                        width: "100%", // Full width on smaller screens
                        height: "47px",
                      }}
                      onClick={handleWhatsappClick}
                    >
                      WhatsApp
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        ) : showEmailForm ? (
          <EarlyWarn key="earlyWarn" />
        ) : (
          <WhatsappWarn key="whatsappWarn" /> // New WhatsApp form
        )}
      </Container>
    </div>
  );
}
